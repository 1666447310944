<template>
  <div>
    <h1 class="main-header" v-translate translate-context="form'">Create</h1>
    <Form :data=empty @save="onSave" :save_text="$pgettext('form','Create')"/>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import Form from "@/domain/eventTemplate/components/Form.vue";
import {SuccessNotification} from "@/domain/core/exception/exceptions";

export default {
  name: 'NewEventTemplate',
  components: {Form},
  data() {
    return {
      empty: {
        name: '',
        allows_profile_event_photo: false,
        allows_photo_watermark: false,
        allows_advertisement: false,
        allows_public_photo_and_video: false,
        allows_form: false,
        allows_checkin_options: false,
        allows_customization_setup: false,
        allows_popup: false,
        allows_photo_map: false,
        allows_browse_photo: false,
        allows_multiple_galleries: false,
        show_all_event_photo: false,
        enable_scheduler: false,
        date_type: 's',
      }
    }
  },
  methods: {
    ...mapActions('eventTemplate', [
      'createTemplate',
    ]),
    async onSave(data) {
      await this.createTemplate(data)
      await this.$router.push({name: 'eventTemplate.list'})
      throw new SuccessNotification(this.$pgettext('event_template', 'Template created!'))
    }
  }
}
</script>

<style scoped>

</style>